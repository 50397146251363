.main-container-onboarding {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  box-sizing: border-box;
  font-family: 'Archivo', sans-serif;
}

