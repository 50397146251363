.calendar-days {
  width: 330px;
  height: 380px;  /* Increased to accommodate buttons */
  font-family: 'Archivo', sans-serif;
  padding-bottom: 20px;  /* Added padding instead of margin */
}

.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 12px;
  padding-top:15px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.nav-button img {
  width: 16px;
  height: 16px;
}

.current-month {
  font-size: 15px;
  font-weight: 500;
  color: #5b5e6c;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  padding: 0 12px;
  margin-bottom: 8px;
}

.weekday {
  font-size: 15px;
  font-weight: 300;
  color: #5b5e6c;
  text-align: center;
  padding: 5px 0;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
  padding: 0 12px;
}

.calendar-day {
  width: 40px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 300;
  color: #5b5e6c;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.calendar-day:hover {
  background-color: #f5f5f5;
}

.calendar-day.selected {
  background-color: #16a657;
  color: #ffffff;
  border-radius: 8px;
}

.calendar-day.empty {
  cursor: default;
}

.calendar-day.empty:hover {
  background: none;
}

select.current-month {
  padding: 5px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  min-width: 100px;
  text-align: center;
}

select.current-month:hover {
  border-color: #aaa;
}

select.current-month:focus {
  border-color: #666;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
}

/* Custom arrow styling */
select.current-month {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 12px;
  padding-right: 25px;
}

.calendar-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px 12px 0 12px;
  margin-top: 10px;
}

.calendar-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  transition: all 0.2s;
}

.calendar-button.cancel {
  background-color: #f5f5f5;
  color: #5b5e6c;
}

.calendar-button.select {
  background-color: #16a657;
  color: white;
}

.calendar-button:hover {
  opacity: 0.9;
}

.calendar-button.cancel:hover {
  background-color: #eaeaea;
}

