.container {
    display: block;
    position: relative;
    padding-left: 30px;
    padding-top: 3px;
    cursor: pointer;
    font-size: 1em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #00000088;
}

/* Hide the browser's default checkbox */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-color: #ddd;
    border: solid;
    border-radius: 5px;
    border-width: 2px;
}

/* On mouse-over, add a grey background color */


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {

}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
    display: block;


}

/* Style the checkmark/indicator */
.container .checkmark:after {
    left: 4px;
    top: 2px;
    width: 8px;
    height: 8px;
    border: solid #2196F3;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}
