.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  gap: 24px;
  width: 100%;
}

.month-year {
  flex: 1;
  font-family: 'Archivo', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  color: #5b5e6c;
  text-align: center;
}

.nav-button {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button:hover {
  opacity: 0.8;
}

.nav-button:focus {
  outline: none;
}

.nav-button img {
  display: block;
}

