.otp-submit-container {
  width: min(680px, 100%);
  min-height: 100vh;
  padding: clamp(20px, 5vw, 100px);
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-content {
  width: min(400px, 100%);
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.otp-header {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;
}

.otp-title {
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  color: #25272f;
  margin: 0;
}

.otp-subtitle {
  font-family: 'Archivo', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #5b5e6c;
  margin: 0;
}

.otp-input-section {
  margin-bottom: 24px;
}

.otp-input-group {
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.otp-input {
  width: clamp(45px, 12vw, 60px);
  height: clamp(54px, 14vw, 72px);
  border: 1px solid #cccdd7;
  border-radius: 8px;
  text-align: center;
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  font-size: clamp(18px, 5vw, 24px);
  line-height: 38px;
  color: #5b5e6c;
  background: #ffffff;
}

.otp-input:focus {
  outline: none;
  border-color: #16a657;
}

.otp-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.submit-btn, .cancel-btn {
  width: 100%;
  height: 46px;
  border-radius: 28px;
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  border: none;
  padding: 12px 22px;
  text-align: center;
}

.submit-btn {
  background: #16a657;
  color: #ffffff;
}

.submit-btn:hover {
  background: #148a49;
}

.cancel-btn {
  background: #ffffff;
  color: #5b5e6c;
  border: 1px solid #cccdd7;
}

.cancel-btn:hover {
  background: #f5f5f5;
}

@media (max-width: 480px) {
  .otp-submit-container {
    padding: 16px;
  }

  .otp-title {
    font-size: 20px;
    line-height: 30px;
  }

  .otp-subtitle {
    font-size: 14px;
    line-height: 22px;
  }

  .otp-input {
    margin: 4px;
  }
}

@media (max-width: 550px) {
  .otp-submit-container {
    width: 100%;
    padding: 16px;
    margin: 0;
    justify-content: flex-start;
    padding-top: 60px; /* Space for header */
  }

  .otp-content {
    width: 100%;
    padding: 0 10px;
  }

  .otp-input-group {
    gap: 8px;
    justify-content: center;
    padding: 0;
  }

  .otp-input {
    width: 40px;
    height: 50px;
    font-size: 18px;
    margin: 4px;
  }

  .otp-buttons {
    padding: 0 10px;
  }

  .submit-btn, 
  .cancel-btn {
    margin: 5px 0;
  }
}
