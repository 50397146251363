.login-form {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Archivo', sans-serif;
  margin: 0 auto;
  padding: 20px;
}

.login-header {
  text-align: center;
  margin-bottom: 32px;
}

.login-header h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #25272f;
  margin-bottom: 6px;
}

.login-header p {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: #5b5e6c;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.social-button {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #fff;
  border: 1px solid #cccdd7;
  border-radius: 28px;
  cursor: pointer;
  padding: 12px 20px;
}

.social-icon {
  width: 18px;
  height: 18px;
}

.social-button span {
  font-size: 15px;
  font-weight: 500;
  color: #5b5e6c;
  line-height: 22px;
}

.divider {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
}

.divider .line {
  flex: 1;
  height: 1px;
  background-color: #cccdd7;
}

.divider span {
  color: #9096a9;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.login-form-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-group label {
  font-size: 13px;
  font-weight: 500;
  color: #25272f;
  line-height: 20px;
}

.input-field {
  position: relative;
}

.input-field input {
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  padding-right: 44px;
  border: 1px solid #cccdd7;
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #25272f;
  background: #fff;
}

.input-field input::placeholder {
  color: #9096a9;
}

.field-icon {
  position: absolute;
  right: 16px;
  top: 32%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.field-icon2 {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
  height: 15px;
}

.field-icon.clickable {
  cursor: pointer;
}

.forgot-password {
  align-self: flex-start;
  background: none;
  border: none;
  color: #5b5e6c;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 2px 0;
}

.sign-in-button {
  width: 100%;
  height: 46px;
  background: #16a657;
  color: #fff;
  border: none;
  border-radius: 28px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  padding: 12px 22px;
  margin-top: 8px;
}

.signup-section {
  text-align: center;
  margin-top: 16px;
}

.signup-section p {
  color: #5b5e6c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.sign-up-button {
  width: 100%;
  height: 46px;
  background: #fff;
  border: 1px solid #cccdd7;
  border-radius: 28px;
  color: #5b5e6c;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  padding: 12px 22px;
}







.auth-container{
  display: flex;
}

.auth-left-container{
  height: 100vh;
  width: 53.5vw;
  display: flex;
  justify-content: center;
}

.auth-left-container img{
  position: absolute;
  left: 0;
  height: 100vh;
  width: 53.5vw;
  z-index: 1;
}

.auth-left-title{
  width: 50vw;
  font-size: 2em;
  margin-top: 50px;
  color:white;
  z-index: 2;
}

.auth-right-container{
  display: flex;
  align-items: center;
  height: 100vh;
  width: 46.5vw;
  flex-direction: column;
  justify-content: center;
}

.auth-right-inner-container{

}

.auth-right-title{
  font-size: 24px;
  font-family: 'Archivo';
  font-weight: 600;
  line-height: 36px;
  width: 32vw;
  text-align: center;
  color: #25272F;
}

.auth-right-blue-divider{
  height: 5px;
  width: 160px;
  align-self: center;
  margin-top: 4px;
  margin-left: calc(50% - 80px);
}


.login-container{
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.auth-text{
  border: 2px solid #DFDFDF;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  height:53px;
  border-radius: 10px;
  margin-top: 16px;
}

.auth-drawable-left{
  margin-left: 8px;
  margin-right: 8px;
  height: 30px;
  width: 30px;
}

.auth-verticle-divider{
  width: 2px;
  background-color:#DFDFDF ;
  height: 53px;
}

.auth-text input{
  border: none;
  outline: none;
  font-size: 1em;
  margin-left: 8px;
  width: 100%;
}

::placeholder{
  color: #00000088;
}

.auth-drawable-right{
  margin-left: auto;
  margin-right: 10px;
}

.auth-action-container{
  width: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.auth-action-container button{
  margin-left: auto;
  background-color: #3A7CFF;
  border: none;
  color: white;
  font-size: 1em;
  border-radius: 6px;
  padding: 8px 35px;
  cursor: pointer;
}

.auth-forgot-container{
  width: 75%;
  display: flex;
  margin-top: 16px;
}

.auth-forgot-container span{
  margin-left: auto;
  color: #00000088;
}

.auth-different{
  color: #3A7CFF;
  margin-top: 36px;
}

.auth-different-button{
  cursor: pointer;
  background-color: #50CB88;
  border: none;
  color: white;
  font-size: 1em;
  border-radius: 6px;
  padding: 8px 52px;
  margin-top: 10px;
}

.auth-or-container{
  width: 75%;
  margin-left: 12.5%;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.auth-or-divider{
  height: 2px;
  width:100%;
  background-color: #00000044;
}

.auth-or{
  color:#00000088;
  background-color: white;
  padding: 0px 20px;
}

.auth-google-container{
  display: flex;
  align-items: center;
  width: 75%;
  margin-left: 12.5%;
  background-color:#C42D23;
  border-radius: 6px;
  margin-top: 20px;
  cursor: pointer;
}

.auth-google-left-container{
  height: 100%;
  padding-left: 10px;
  padding-right: 2px;
}

.auth-google-container img{
  height: 75%;
  margin-top: 12.5%;
  width: 75%;
}

.auth-google-right-container{
  width: 100%;
  color: white;
  background-color:#F44334 ;
  padding: 8px 10px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
}

.auth-home-container{
  cursor: pointer;
  position: absolute;
  left: 53.5vw;
  padding: 10px;
  display: flex;
  align-items: center;
}

.auth-home-container img{
  width:30px;
  height: 30px;
  margin-right: 10px;
}

@media (max-width:1000px) {
  .auth-container{
      justify-content: center;
      height: 100%;
  }
  .auth-home-container{
      left:0vw;
  }
  .auth-right-title{
      width:100%;
  }
  .auth-text{
      width: 100%;
  }
  .auth-action-container{
      width: 100%;
  }
  .auth-forgot-container{
      width: 100%;
  }
  .auth-right-container{
      width: 100%;
  }
  .auth-left-container{
      display: none;
  }

}


.main-layout {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #fdf4e3;
  position: relative;
  overflow-x: hidden;
}

.left-section {
  width: 53%;
  min-width: 760px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fdf4e3;
  padding: 40px;
}

.right-section {
  width: 47%;
  min-width: 680px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-color: #ffffff;
  padding: 40px;
  padding-top:76px;
}

/* Update media queries to maintain layout */

@media (max-width: 768px) {
  .main-layout {
    flex-direction: column;
  }

  .left-section {
    display: none;
  }

  .right-section {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    padding-top:76px;
    
  }

  .login-form {
    width: 100%;
    padding: 15px;
  }

  .social-button {
    width: 100%;
  }

  .input-field {
    width: 100%;
  }

  .input-field input {
    width: 100%;
    max-width: none;
  }
  
  .login-header p {
    width: 100% !important;
    padding: 0 20px;
  }
}

@media (min-width: 769px) and (max-width: 1420px) {
  .left-section,
  .right-section {
    padding: 30px;
    min-width: unset;
    padding-top:76px;
    justify-content: center;
    align-items: baseline;
  }

  .login-form {
    width: 90%;
  }
}

@media (max-width: 550px) {
  body{
 
   justify-content: center;
   align-items:center;
   display:flex;
  }
  .right-section {
   width: 100%;
   max-width: 100%;
   padding: 20px;
   padding-top:76px;
   padding-left:9em;
   
 }
   }