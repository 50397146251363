.illustration-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 760px;
  background-color: #FFF3DB;
  padding: clamp(15px, 3vw, 20px);
  box-sizing: border-box;
  overflow: hidden;
}

.illustration-container {
  width: 100%;
  position:relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the image doesn't overflow the container */
}
.title {
  font-family: 'Archivo', sans-serif;
  font-weight: 600;
  font-size: clamp(20px, 4vw, 29px);
  line-height: 1.6;
  color: #25272f;
  margin: 0;
  text-align: center;
}


.main-illustration {
  width: 100%;
  max-width: min(100%, 700px);
  height: auto;
  object-fit: contain; /* Ensures the image maintains its aspect ratio */
  display: block;
}

.illustration-description {
  font-size: 16px;
  color: #333;
  text-align: center;
  margin-top: 20px;
  line-height: 1.5;
}
.description {
  font-family: 'Archivo', sans-serif;
  font-weight: 300;
  font-size: clamp(14px, 3vw, 16px);
  line-height: 1.6;
  color: #5b5e6c;
  position: relative;
  margin: 0 auto;
  max-width: min(100%, 582px);
  text-align: center;
  padding: 0 15px;
}

@media (max-width: 768px) {
  .illustration-section {
    padding: 15px;
  }
  
  .description {
    margin-left: 0;
  }
}
