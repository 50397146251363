.form-section {
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: 'Archivo', sans-serif;
}

.input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 69px;
}

.calendar-popup {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 8px;
}

.label-frame {
  width: 100%;
  display: flex;
  gap: 4px;
  height: 20px;
}

.label-text {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #686f86;
  text-align: left;
}

.input-container {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cccdd7;
  border-radius: 30px;
  padding: 0 16px;
  box-sizing: border-box;
}

.input-container input {
  width: 100%;
  height: 20px;
  border: none;
  outline: none;
  font-family: 'Archivo', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #25272f;
  background: transparent;
}

.input-container input::placeholder {
  color: #9096a9;
}

.input-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.calendar-icon {
  margin-left: 10px;
}

.caret-icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}

/* Specific styles for the name input field */
.input-field:first-child .input-container input::placeholder {
  color: #5b5e6c;
}

.input-field:first-child .label-text {
  color: #686f86;
}

/* Specific styles for date of birth and class labels */
.input-field:nth-child(2) .label-text,
.input-field:nth-child(3) .label-text,
.input-field:nth-child(4) .label-text {
  color: #25272f;
}
