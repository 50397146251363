.header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-content {
  width: 100%;
  padding: 26px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-family: 'Archivo', sans-serif;
}

.header-step {
  display: flex;
  align-items: center;
  gap: 8px;
}

.step-indicator {
  width: 22px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-indicator.completed {
  background-color: #16A657;
  padding: 4px;
}

.step-indicator.current {
  background-color: #25272F;
  padding: 1px;
}

.check-icon {
  width: 14px;
  height: 14px;
}

.step-number {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.step-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.step-text.completed {
  color: #16A657;
}

.step-text.current {
  color: #25272F;
}

.separator-container {
  display: flex;
  align-items: center;
}

.separator {
  width: 48px;
  height: 1px;
}

.progress-bar-container {
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 4px;
  display: block;
}
