.homepage {
  &-nav {
    height: 10vh;
    margin: 10px 50px 0px 240px;

    @media (max-width: 1000px) {
      margin: 10px 50px 0px 150px;
      font-size: 12px;
    }
    @include breakpoint-down(small) {
      height: 8vh;
      margin: 0px 0px 0px 0px;
    }

    &-mob {
      margin: 20px 16px 0px 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-logo {

        @include breakpoint-up(medium) {
          display: none;
        }
        img {
          width: 160px;
        }
      }

      &-hamburger {
        @include breakpoint-down(small) {
          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          margin-right: 20px;

          cursor: pointer;
          > span {
            flex-direction: column;
            background-color: #081240;
            border: 1.5px solid #081240;
            border-radius: 20px;

            border-radius: 50px;
          }
          > span:nth-child(1) {
            width: 30px;
          }
          > span:nth-child(2) {
            margin-top: 5px;
            width: 23px;
          }
          > span:nth-child(3) {
            margin-top: 5px;
            width: 15px;
          }
        }
      }
    }

    &-links {
      @include breakpoint-down(small) {
        display: none;
      }
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #081240;
      font-size: 14px;
      @media (max-width: 870px) {
        font-size: 12px;
      }
      &-logo {
        flex: 2;
        width: 180px;

        img {
          width: 180px;
          @media (max-width: 1050px) {
            width: 160px;
          }
        }
      }

      > a {
        margin-top: 10px;
        color: #081240;
        text-decoration: none;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        font-size: 16px;
        @media (max-width: 900px) {
          font-size: 12px;
          text-align: center;
          margin-top: 14px;
        }
        @include breakpoint-down(small) {
          padding: 10px;
        }
      }
      > a:hover {
        opacity: 0.7;
      }
      > a:first-child {
        flex: 1.2;
      }
      > a:not(:first-child) {
        flex: 1;
      }
      &-buttons {
        margin-top: 10px;
        flex: 2.5;
        display: flex;

        align-items: center;
        margin-bottom: 0;
        justify-content: center;
        color: #3a7cff;
        font-weight: 600;

        @include breakpoint-down(small) {
          flex-direction: column;
        }

        &-login {
          padding-right: 10px;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          margin-bottom: 0px;
          &:hover {
            opacity: 0.5;
          }
        }

        &-signup {
          margin-bottom: 0px;
          padding: 5px 20px;
          border: 3px solid #3a7cff;
          border-radius: 30px;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          &:hover {
            color: white;
            background-color: #3a7cff;
          }
        }
      }
    }

    &-moblinks {
      // visibility: hidden;
      @include breakpoint-up(medium) {
        display: none;
      }
      position: absolute;
      top: 0vh;
      z-index: 10000;
      background-color: #557fd4;
      // backdrop-filter: blur(4px);
      width: 70vw;
      height: 100vh;
      text-align: left;
      transform: translateX(200%);
      transition: all 200ms ease-in-out;

      &-closeBtn {
        width: 100%;
        text-align: right;
        margin-top: 35px;
        margin-left: -51px;
        > img {
          width: 40px;
        }
      }
      > ul {
        height: 60%;
        padding: 0px;
        list-style: none;
        margin-top: 7vh;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        > li {
          color: white;
          font-size: 18px;
          font-weight: 500;

          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.menu_active {
  transform: translateX(50%);
  position: fixed;
}
