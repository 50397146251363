.login-form {
  width: 680px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-family: 'Archivo', sans-serif;
  margin: 0 auto;
  padding: 20px;
}

.login-header {
  text-align: center;
  margin-bottom: 32px;
}

.login-header h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #25272f;
  margin-bottom: 6px;
}

.login-header p {
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: #5b5e6c;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.social-button {
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #fff;
  border: 1px solid #cccdd7;
  border-radius: 28px;
  cursor: pointer;
  padding: 12px 20px;
}

.social-icon {
  width: 18px;
  height: 18px;
}

.social-button span {
  font-size: 15px;
  font-weight: 500;
  color: #5b5e6c;
  line-height: 22px;
}

.divider {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
}

.divider .line {
  flex: 1;
  height: 1px;
  background-color: #cccdd7;
}

.divider span {
  color: #9096a9;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
}

.login-form-fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input-group label {
  font-size: 13px;
  font-weight: 500;
  color: #25272f;
  line-height: 20px;
}

.input-field {
  position: relative;
}

.input-field input {
  width: 100%;
  height: 44px;
  padding: 12px 16px;
  padding-right: 44px;
  border: 1px solid #cccdd7;
  border-radius: 30px;
  font-size: 14px;
  line-height: 20px;
  color: #25272f;
  background: #fff;
}

.input-field input::placeholder {
  color: #9096a9;
}

.field-icon {
  position: absolute;
  right: 16px;
  top: 32%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.field-icon2 {
  position: absolute;
  right: 16px;
  top: 32%;
  transform: translateY(-50%);
  width: 26px;
  height: 15px;
}

.field-icon.clickable {
  cursor: pointer;
}

.forgot-password {
  align-self: flex-start;
  background: none;
  border: none;
  color: #5b5e6c;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 2px 0;
}

.sign-in-button {
  width: 100%;
  height: 46px;
  background: #16a657;
  color: #fff;
  border: none;
  border-radius: 28px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  padding: 12px 22px;
  margin-top: 8px;
}

.signup-section {
  text-align: center;
  margin-top: 16px;
}

.signup-section p {
  color: #5b5e6c;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
}

.sign-up-button {
  width: 100%;
  height: 46px;
  background: #fff;
  border: 1px solid #cccdd7;
  border-radius: 28px;
  color: #5b5e6c;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  padding: 12px 22px;
}
