/* body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    /* padding: 16px; */
    /* box-sizing: border-box; */
/* } */ 

.password-updated-unique-container {
    width: 100%;
    max-width: 400px;
    padding: 32px 24px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
    margin: 0 auto;
}

.success-icon-unique {
    margin-bottom: 20px;
}

.message-container-unique {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    padding: 0 15px;
}

.title-unique {
    font-family: 'Archivo', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #25272f;
    margin: 0;
}

.description-unique {
    font-family: 'Archivo', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #5b5e6c;
    margin: 0;
}

.dashboard-button-unique {
    width: 100%;
    max-width: 400px;
    height: 46px;
    background: #16a657;
    color: #fff;
    border: none;
    border-radius: 28px;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    padding: 12px 22px;
    margin-top: 8px;
}

.dashboard-button-unique:hover {
    background-color: #148a49;
}

@media screen and (max-width: 480px) {
   

    .password-updated-unique-container {
        padding: 24px 16px;
        gap: 24px;
        width: 100%;
    }

    .title-unique {
        font-size: 20px;
        line-height: 32px;
    }

    .description-unique {
        font-size: 14px;
        line-height: 22px;
    }

    .success-icon-unique {
        margin-bottom: 10px;
        transform: scale(0.9);
    }

    .dashboard-button-unique {
        font-size: 14px;
        height: 42px;
        width: 100%;
        margin-top: 8px;
    }

    .message-container-unique {
        padding: 0;
        width: 100%;
    }
}
