.title-section {
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
}

.title-section__heading {
  width: 100%;
  font-family: 'Archivo', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  color: #25272f;
  text-align: center;
  margin: 0;
}

.title-section__subheading {
  width: 100%;
  font-family: 'Archivo', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: #5b5e6c;
  text-align: center;
  margin: 0;
}

