.userDashboard {
  min-height: 100vh;
  display: flex;
  &-sidemenu {
    height: 100vh;
    // position: fixed;
    background-color: #f4f5f7;

    @media (max-width: 900px) {
      display: none;
    }

    &-logo {
      padding: 0px 40px;
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      > img {
      }
    }
    &-menu {
      &-option {
        padding: 20px 40px;
        display: flex;
        align-items: center;
        > img {
        }
        > h3 {
          color: #b9c0de;
          margin-left: 20px;
          font-size: 16px;
        }
      }
    }
    &-promo {
      margin-top: 100px;

      padding: 0px 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;
      &-background {
        z-index: -1;
        left: 0px;
        position: absolute;
        background-color: rgba(235, 238, 255, 1);
        border-radius: 30px;

        top: 68px;
        text-align: center;
        height: 87%;
        width: 88%;
        margin: 0px 20px;
      }
      > img {
      }
      > p {
        width: 80%;
        text-align: center;
        margin: auto;
        color: rgba(27, 0, 49, 1);
        > span {
          color: rgba(28, 78, 255, 1);
          font-weight: bold;
        }
      }
      > button {
        background-color: #1657ff;
        color: white;
        border: none;
        padding: 20px 0px;
        border-radius: 16px;
        transition: all 300ms ease-in-out;
      }
      > button:hover {
        opacity: 0.7;
      }
    }
  }
  &-gap {
    // margin-left: 360px;
    @media (max-width: 900px) {
      margin-left: 0px;
    }
  }

  &-main {
    width: 100%;
    &-nav {
      width: 100%;
      border-bottom: 1px solid #d1dbe5;
      &-wrapper {
        width: 90%;
        margin: auto;
        padding: 20px 0px;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        > img {
          border-radius: 50%;
          margin: 0px 10px;
        }
        > p {
          font-weight: bold;
        }
        &-userpic {
          width: 50px;
        }
      }
    }
    &-userinfo {
      width: 90%;

      margin: 40px auto;
      padding: 30px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #d1dbe5;
      box-shadow: 0px 2px 8px 0px rgba(72, 79, 95, 0.08);

      &-data {
      }
      &-pic {
        > img {
          width: 100px;
          border-radius: 50%;
          @media (max-width: 900px) {
            width: 100px;
          }
        }
      }
    }
    &-statistics {
      width: 90%;
      margin: auto;
      margin-top: 20px;
      &-levelbar {
        margin: 30px 0px;
        display: flex;
        > p {
          padding: 5px 20px;
          color: #8b8d9b;
          font-weight: 500;
          cursor: pointer;

          text-align: center;
          border-top: 1px solid #3d7eff;
          border-bottom: 1px solid #3d7eff;
        }
        > p:first-child {
          border-left: 1px solid #3d7eff;
        }
        p:last-child {
          border-right: 1px solid #3d7eff;
        }
        .activelevel {
          background-color: #3d7eff;
          color: white;
        }
      }
      &-cards {
        margin: 30px 0px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        @media (max-width: 1250px) {
        }
        &-card {
          border: 1px solid #8b8d9b;
          box-shadow: 0px 2px 8px 0px rgba(72, 79, 95, 0.08);
          border-radius: 5px;
          &-top {
            padding: 20px 30px;
            display: flex;
            gap: 20px;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #8b8d9b;
            &-title {
              > p {
                color: rgba(44, 47, 59, 1);
                font-weight: 700;
              }
              &-bottom {
                display: flex;
                gap: 5px;
                flex-direction: column;
                > h4 {
                  color: rgba(53, 62, 108, 1);
                  font-weight: bold;
                  font-size: 35px;
                }
                &-chip {
                  border: 1px solid #8b8d9b;
                  padding: 2px 10px;
                  border-radius: 20px;
                  width: 110px;

                  > p {
                    color: #8b8d9b;
                    font-weight: 500;
                    font-size: 12px;
                  }
                }
              }
            }
            &-img {
              > img {
              }
            }
          }
          &-bottom {
            padding: 20px 30px;
            &-data {
              display: flex;
              justify-content: space-between;
              gap: 30px;
              > p {
                color: rgba(139, 141, 155, 1);
                font-weight: 500;
                padding: 8px 0px;
              }
            }
          }
        }
      }
    }
    &-rankings {
      margin: 20px auto;
      width: 90%;
      &-cards {
        margin-top: 20px;
        display: flex;
        gap: 30px;
        @media (max-width: 900px) {
          flex-direction: column;
        }

        &-card {
          border-radius: 3px;
          background-color: rgba(244, 71, 113, 1);
          display: flex;
          gap: 50px;
          padding: 10px 20px;
          &-left {
            > p {
              color: white;
            }
            > h4 {
              color: white;
              font-size: 30px;
            }
          }
          &-right {
            > img {
            }
          }
        }
      }
    }
  }
}

.pheading {
  color: rgba(53, 62, 108, 1);
  font-weight: bold;
  font-size: 24px;
  @media (max-width: 900px) {
    font-size: 18px;
  }
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.navactive {
  background: #ffffff;
}
