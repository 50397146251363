.progress-tracking {

}

.progress-title {
    color: #081240;
    font-size: 1.2rem;
    font-weight: bold;
}

// .vbox{
//     .level-container {
//         font-size: 1vw;
//         // font-weight: bold;
//       }
// }


@media (max-width:750px) {
    .level-container {
      font-size: 10px;
      font-weight: bold;
    }
}

.hbox {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.vbox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid-container{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2vw;
    padding-top: 1.5rem;
    box-sizing:border-box;
    .topic-container{
        padding: 1rem;
    }
}
.grid-title{
    // display: block;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    padding: 30px;
    background-color: #3D7EFF;
    font-size: 20px;
    border-radius: 7px;
}

.grid-item {
    min-width: 0px;
    // border: 2px solid #191919;
    // background-color: #191919;
    border-radius:0 0 4% 4%;
    width: calc(25% - 1.5vw);
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}