.button-section {
  display: flex;
  gap: 10px;
  width: 540px;
  min-height: 46px;
  align-items: right;
  justify-content: right;
}

.button-section__later {
  display: flex;
  padding: 12px 22px;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  border: 1px solid #CCCDD7;
  background: #FFF;
  color: #5B5E6C;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-section__later:hover {
  background: #F5F5F5;
}

.button-section__submit {
  display: flex;
  padding: 12px 22px;
  align-items: center;
  gap: 8px;
  border-radius: 28px;
  background: #16A657;
  border: none;
  color: #FFF;
  font-family: Archivo;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button-section__submit:hover {
  background: #138A48;
}

.button-section__later:focus,
.button-section__submit:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(22, 166, 87, 0.2);
}

