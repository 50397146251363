.score-details {
  background-color: #8080801a;
  &-problems {
    width: 90%;
    margin: 0px auto;
    padding-bottom: 20px;
    &-heading {
      display: flex;
      gap: 20px;
      margin: 20px 0px;

      > h3 {
        width: 100%;

        border-radius: 12px;
        text-align: center;
        font-size: 16px;

        padding: 10px 0px;
        background-color: #3d7eff;
        color: white;
        font-weight: bold;
      }
      > h3:nth-child(2) {
        width: 20%;
      }
    }
    &-problem {
      display: flex;
      gap: 20px;
      margin: 20px 0px;

      > h3 {
        width: 100%;
        background-color: white;
        border-radius: 12px;
        text-align: center;
        font-size: 16px;

        padding: 10px 0px;
        font-weight: bold;
      }
      > h3:nth-child(2) {
        width: 20%;
        // background-color: #45c881;
        color: white;
        font-weight: bold;
      }
    }
  }
}
