@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

*{
  font-family: 'Montserrat', sans-serif;
}

body {
  margin: 0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffda4722;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #485253;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d837e;
}
